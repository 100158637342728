<template>
    <div
        v-if="visible"
        class="dropdown"
        :style="{ top: position.top + 'px', left: position.left + 'px' }"
        @mouseenter="dropdown_mouseenter($event, true)"
        @mouseleave="dropdown_mouseleave($event, true)"
    >
        <!-- <Widget :widget_id="widget_id" /> -->
        <div class="commandPanel-dropdown" v-if="commandPanel.length">
            <component
                v-for="attrM in commandPanel"
                :key="attrM"
                :widget_id="widget_id" 
                :is="attrM.component"
                :attr_id="attrM.id"
                :initialAttr="attrM"
            />
        </div>

        <ul>
            <li v-for="(item, index) in items" :key="index" @click="selectItem(item)">
                <span v-html="item.highlightedTitle"></span>
            </li>
        </ul>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    data() {
        return {
            // widget_id:'dropdown',
            visible: false,
            position: { top: 0, left: 0 },
            items: [],
            onSelect: null,
        
            commandPanel: [],
            ind_commandPanel: 0,

            itemsSource_segment: null,
            marginLeft: 20,
            marginTop: -1,
        }
    },
    setup() {
        const store = useMainStore()
        return { store }
    },
    created() {
        // this.store.WIDGETS[this.widget_id] = {
        //     id: this.widget_id,
        //     vueObj: this,
        //     attrs_vueObj: {
        //         WIDGET: this,
        //     },
        // }
        document.addEventListener('mousemove', (event) => {
            this.store.mouseX = event.clientX
            this.store.mouseY = event.clientY
        })

        // document.addEventListener('keydown', function(event) {
        //     if (event.code === 'Space') {
        //         event.preventDefault()

        //         this.set_use(e, checked, binding, r1, r2)
        //         console.log('Spacebar pressed!')
        //     }
        // })
    },
    mounted() {
        this.mouseenter = (event, isDropdown=false) => this.dropdown_mouseenter(event, isDropdown)
        this.mouseleave = (event, isDropdown=false) => this.dropdown_mouseleave(event, isDropdown)
        this.blur = (event) => this.dropdown_blur(event)
    },
    methods: {
        show(check_mouse_isInside=false) {
            const dropdown = this
       
            dropdown.clear_timer()
            dropdown.set_position()
            const canBeVisible = dropdown.divCell && (!check_mouse_isInside || dropdown.mouse_isInside)
            if (!canBeVisible) {
                dropdown.hide()
            } else if (!dropdown.visible) {
                dropdown.show_timerId = setTimeout(() => {
                    const cssClass = 'pi p-button-white p-button-text mr-0 '
                    dropdown.commandPanel = []
                    for (const attrMenu of dropdown.rowMenu) {
                        dropdown.commandPanel.push({
                            ...attrMenu,
                            cssClass: cssClass + (attrMenu.cssClass || ''),
                            ...dropdown.parent_attr.cell,
                            'binding':dropdown.binding,
                        })
                    }

                    // dropdown.commandPanel = [
                    //     { 'attr_type':'AttrButton', 'tooltip': 'Records', 'command':'widget_open', 'widget':'widget-list', 'cssClass':cssClass+'pi-folder-open', ...dropdown.parent_attr.cell },
                    //     { 'attr_type':'AttrButton', 'tooltip': 'Search', 'command':'executeVueObjMethod', 'method':'onButtonSearch__Click', 'cssClass':cssClass+'pi-search', ...dropdown.parent_attr.cell },
                    //     { 'attr_type':'AttrButton', 'tooltip': 'Toggle choose in list', 'command':'executeVueObjMethod', 'method':'onButtonСhoiceStart__Click', 'cssClass':cssClass+'pi-ellipsis-h', ...dropdown.parent_attr.cell },
                    //     { 'attr_type':'AttrButton', 'tooltip': 'Select', 'command':'executeVueObjMethod', 'method':'onButtonShowDroppedDown__Click', 'cssClass':cssClass+'pi-angle-down', ...dropdown.parent_attr.cell },
                        
                    //     // { 'attr_type':'AttrMenu', 'tooltip': 'Action', 'cssClass':cssClass+'pi-bars', 'attrs':[
                    //     //     { 'attr_type':'AttrButton', 'title': 'Select', 'command':'executeVueObjMethod', 'method':'onButtonShowDroppedDown__Click', 'cssClass':cssClass+'pi-angle-down', ...dropdown.parent_attr.cell },
                    //     // ]},
                        
                    //     { 'attr_type':'AttrButton', 'tooltip': 'Open', 'command':'executeVueObjMethod', 'method':'onButtonOpen__Click', 'cssClass':cssClass+'pi-arrow-right', ...dropdown.parent_attr.cell },
                    // ]

                    dropdown.store.attrs_prepare(dropdown.commandPanel, 'dropdown' + (dropdown.ind_commandPanel))
                    
                    dropdown.visible = true

                    // dropdown.addObserver()
                    dropdown.set_check_position_timerId = setInterval(() => {
                        dropdown.check_position()
                    }, 100)
                }, 100)
            }
        },
        addObserver() {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    console.log(':', entry.target);
                    console.log(':', entry.contentRect);
                }
            });
            resizeObserver.observe(dropdown.divCell);

            const intersectionObserver = new IntersectionObserver(entries => {
                for (let entry of entries) {
                    console.log(':', entry.target);
                    console.log(':', entry.boundingClientRect);
                }
            });
            intersectionObserver.observe(dropdown.divCell);

            const mutationObserver = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.attributeName === 'style') {
                        if (this.visible) {
                            this.set_position()
                        }
                    }
                })
            })
            mutationObserver.observe(dropdown.divCell, {
                attributes: true,
                attributeFilter: ['style']
            })
        },
        hide() {
            if (this.visible) {
                this.visible = false
                this.clear_timer()
            }
        },
        selectItem(item) {
            if (this.onSelect) {
                this.onSelect(item)
            }
            this.hide_items()
        },
        dropdown_mouseenter(e, isDropdown=false) {
            this.clear_timer()
            if (!isDropdown) {
            this.clear_timer()
            this.show()
            }
        },
        dropdown_mouseleave(e, isDropdown=false) {
            this.clear_timer()
            if (this.visible && !this.activeEditor) {
                this.hide_timerId = setTimeout(() => {
                    this.hide()
                }, 500)
            }

            // this.divCell.style.border = 'none'
        },
        dropdown_blur(e) {
            this.hide_items()
        },
        hide_items() {
            this.hide_items_timerId = setTimeout(() => {
                this.items = []
            }, 500)
        },
        clear_timer() {
            if (this.hide_timerId) {
                clearTimeout(this.hide_timerId)
                this.hide_timerId = null
            }
            if (this.show_timerId) {
                clearTimeout(this.show_timerId)
                this.show_timerId = null
            }
            if (this.showForActiveCell_timerId) {
                clearTimeout(this.showForActiveCell_timerId)
                this.showForActiveCell_timerId = null
            }
            if (this.hide_items_timerId) {
                clearTimeout(this.hide_items_timerId)
                this.hide_items_timerId = null
            }
            if (this.set_check_position_timerId && !this.visible) {
                clearTimeout(this.set_check_position_timerId)
                this.set_check_position_timerId = null
            }
        },
        set_position() {
            if (this.divCell) {
                // if (this.position_timerId) {
                //     clearTimeout(this.position_timerId)
                //     this.position_timerId = null
                // }
                // this.position_timerId = setTimeout(() => {
                    // this.divCell.style.border = '1px solid #00f'
                    const position = this.get_position()
                    if (this.position.top !== position.top || this.position.left !== position.left) {
                        this.position = position
                    }

                    this.mouse_isInside = this.store.mouse_isInside(this.divCell)
                // }, 10)
            }
        },
        check_position() {
            if (this.divCell && this.visible) {
                const position = this.get_position()
                if (this.position.top !== position.top || this.position.left !== position.left) {
                    this.hide()
                }

            }
        },
        get_position() {
            const rect = this.divCell.getBoundingClientRect(),
            marginTop = this.attr_col?.isNode ? 5 : 0,
            position = {
                top: rect.bottom + window.scrollY + this.marginTop + marginTop,
                left: rect.left + window.scrollX + this.marginLeft,
                divCell: rect,
            }
            return position
        },
        // selectedRow(binding) {
        //     return {
        //         dataItem: this.dataItem,
        //         row: this.parent_attr.cell.row,
        //     }
        // },
        // active_attr_vueObj(binding) {
        //     return this.vueObj
        // },
    },
}
</script>

<style>
.dropdown {
    position: absolute;
    z-index: 9999;
    background: white;
    border: 3px solid rgba(255,150,30,.75); 
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-style: dotted;
}

.dropdown ul {
    padding: 0;
    margin: 0
}

.dropdown li {
    padding: 8px;
    cursor: pointer
}

.dropdown li:hover {
    background-color: #f0f0f0
}

ul .wj-state-match {
    background: #ff0;
    color: #000;
    font-weight: 700;
}

.key-words {
    color: #000;
    font-weight: 900;
}

.commandPanel-dropdown {
    display: flex;
    align-items: center;
}
.p-button:enabled:hover {
    font-weight: bolder;
}
</style>